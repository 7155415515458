import * as Types         from '../mutationTypes';
import HestiaApi          from '@/api';
import StringUtils        from '@/utils/string';
import ObjectManipulation from '@/utils/object';

// initial state
const state = {
  tenant       : {},
  providers    : [],
  agencies     : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  },
  agencies_map : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  }
};

// getters
const getters = {
  tenant       : state => state.tenant,
  providers    : state => state.providers,
  agencies     : state => state.agencies,
  agencies_map : state => state.agencies_map
};

// actions
const actions = {
  // TENANT
  async getTenant({ commit }, { tenantId }) {

    const response = await HestiaApi.Tenant.tenant.get(tenantId).json();
    commit(Types.RECEIVE_TENANT_INFOS, response);
  },
  async editTenant({ commit }, { tenantId, tenant }) {

    const response = await HestiaApi.Tenant.tenant.update(tenantId, tenant).json();
    commit(Types.EDIT_TENANT, response);
  },
  // PROVIDERS
  async addTenantProvider({ commit }, { tenantId, provider, name }) {

    const response = await HestiaApi.Authentication.provider[`update${ StringUtils.ucFirst(name) }`](tenantId, provider).json();
    commit(Types.ADD_PROVIDER, response);
  },
  async getTenantProviders({ commit }, { tenantId }) {

    const response = await HestiaApi.Authentication.provider.list(tenantId).json();
    commit(Types.RECEIVE_PROVIDERS, response);
  },
  async editTenantProvider({ commit }, { tenantId, provider, name }) {

    const response = await HestiaApi.Authentication.provider[`update${ StringUtils.ucFirst(name) }`](tenantId, provider).json();
    commit(Types.EDIT_PROVIDER, response);
  },
  // AGENCIES
  async getTenantAgencies({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Tenant.agency.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_AGENCIES, response);
  },
  async getTenantAgenciesMap({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Tenant.agency.list(tenantId, query).json();
    commit(Types.RECEIVE_AGENCIES_MAP, response);
  },
  async addTenantAgency({ commit }, { tenantId, agency }) {

    const response = await HestiaApi.Tenant.agency.create(tenantId, agency).json();
    commit(Types.ADD_AGENCY, response);
    return response;
  },
  async editTenantAgency({ commit }, { tenantId, agencyId, agency }) {

    const response = await HestiaApi.Tenant.agency.update(tenantId, agencyId, agency).json();
    commit(Types.EDIT_AGENCY, response);
  },
  async removeTenantAgency({ commit }, { tenantId, agencyId }) {

    await HestiaApi.Tenant.agency.delete(tenantId, agencyId);
    commit(Types.REMOVE_AGENCY, agencyId);
  }
};

// mutations
const mutations = {
  [Types.RECEIVE_TENANT_INFOS](state, tenant) {

    state.tenant = ObjectManipulation.clone(tenant);
  },
  [Types.EDIT_TENANT](state, tenant) {

    state.tenant = tenant;
  },
  // PROVIDERS
  [Types.ADD_PROVIDER](state, provider) {

    state.providers.push(provider);
  },
  [Types.RECEIVE_PROVIDERS](state, providers) {

    state.providers = providers;
  },
  [Types.EDIT_PROVIDER](state, provider) {

    const index = state.providers.findIndex((p) => p.name === provider.name);

    state.providers.splice(index, 1, provider);
  },
  [Types.RECEIVE_AGENCIES](state, agencies) {

    state.agencies = agencies;
  },
  [Types.ADD_AGENCY](state, agency) {

    state.agencies.results.unshift(agency);
  },
  [Types.EDIT_AGENCY](state, agency) {

    if (agency.default) {

      const lastDefault = state.agencies.results.findIndex((p) => p.default === true);

      if (lastDefault !== -1 && state.agencies.results[lastDefault].id !== agency.id) {

        state.agencies.results[lastDefault].default = false;
      }
    }

    const index = state.agencies.results.findIndex((gr) => gr.id === agency.id);
    state.agencies.results.splice(index, 1, agency);

  },
  [Types.REMOVE_AGENCY](state, agencyId) {

    const index = state.agencies.results.findIndex((gr) => gr.id === agencyId);
    state.agencies.results.splice(index, 1);
  },
  [Types.RECEIVE_AGENCIES_MAP](state, agencies) {

    state.agencies_map = agencies;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
