import Posthog from 'posthog-js';

export default {

  install(Vue, { token, apiHost }) {

    Vue.prototype.$posthog = Posthog.init(token, { api_host : apiHost, autocapture : false });
  }
};

const registerUser = ({ tenantId, id, firstName, lastName, email, roles, createdAt, groups } = {}) => {

  const properties = {
    tenantId, id, email, roles, createdAt, firstName, lastName,
    fullName     : `${ firstName } ${ lastName }`,
    ac           : false,
    coach        : false,
    groups       : [],
    groups_names : []
  };

  for (const group of groups) {

    if (group.owner === id) {

      if (group.alias) {

        properties.ac = true;
      } else {

        properties.coach = true;
      }
    }

    properties.groups.push(group.id);
    properties.groups_names.push(group.name);
  }

  Posthog.identify(`${ tenantId }_${ id }`, properties);
};

export { Posthog, registerUser };
