import Model  from '../utils/model';
import i18n   from '@/lang'; // Internationalization
import moment from 'moment';

export default class Event extends Model {

  static get model() {

    return {
      calendarId   : '',
      summary      : '',
      description  : '',
      location     : '',
      startAt      : moment().set({ 'hours' : 9, 'minutes' : 0 }),
      endAt        : moment().set({ 'hours' : 10, 'minutes' : 0 }),
      allDay       : false,
      participants : []
    };
  }

  static get actionByPermissions() {

    return {
      'view' : { 'edit' : false, 'delete' : false },
      'edit' : { 'edit' : true, 'delete' : false }
    };
  }

  static get definition() {

    return {

      'calendarId'   : {
        label : 'event.fields.calendar',
        rules : [
          {
            required : true, message  : i18n.t('rules.required', { id : i18n.t('event.fields.calendar') }),
            trigger  : 'blur'
          }
        ]
      },
      'summary'      : {
        label : 'event.fields.summary',
        rules : [
          {
            required : true, message  : i18n.t('rules.required', { id : i18n.t('event.fields.summary') }),
            trigger  : 'blur'
          }
        ]
      },
      'description'  : {
        label : 'event.fields.description',
        rules : [
          {
            required : true, message  : i18n.t('rules.required', { id : i18n.t('event.fields.description') }),
            trigger  : 'blur'
          }
        ]
      },
      'location'     : {
        label : 'event.fields.location'
      },
      'allDay'       : {
        label : 'event.fields.allDay'
      },
      'startAt'      : {
        label  : 'event.fields.startAt',
        event  : {
          change(value, resource, oldValue) {

            if (value && moment(value).diff(moment(oldValue)) !== 0) {

              // Get diff between old Start time et End Time
              const diff = moment(resource.endAt).diff(moment(oldValue));

              // Add the diff to update the end Time
              resource.endAt = moment(value).add(diff);
            }

            return resource;
          }
        },
        format : {
          in(resource) {

            if (resource.allDay) {

              resource.startAt = moment(resource.startAt).utc().format('yyyy-MM-DD');
            }

            return resource;
          }
        },
        rules  : [
          {
            required : true, message  : i18n.t('rules.required', { id : i18n.t('event.fields.startAt') }),
            trigger  : 'blur'
          }
        ]
      },
      'endAt'        : {
        label  : 'event.fields.endAt',
        format : {
          in(resource) {

            if (resource.allDay) {

              resource.endAt = moment(resource.endAt).utc().format('yyyy-MM-DD');
            }

            return resource;
          }
        },
        rules  : [
          {
            required : true, message : i18n.t('rules.required', { id : i18n.t('event.fields.endAt') }), trigger : 'blur'
          },
          {
            id : 'endAt_after_startAt',
            validator(rule, value, callback, source, { form : { value : resource } }) {

              if (resource['startAt'] && resource['endAt']) {

                const startAt = moment.utc(resource['startAt']);
                const endAt = moment.utc(resource['endAt']);

                if (startAt.isAfter(endAt)) {
                  return callback(new Error(i18n.t('rules.endAt_after_startAt')));
                }
              }

              return callback();
            },
            trigger : 'blur'
          }
        ]
      },
      'participants' : {
        label : 'event.fields.participants'
      }
    };
  }
}
