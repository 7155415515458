import * as Types from '../mutationTypes';

import Cookies from 'js-cookie';

import moment from 'moment';

const app = {
  state     : {
    sidebar               : {
      opened : !+Cookies.get('sidebarStatus')
    },
    layout                : 'default',
    tab                   : {
      mandate : 'details'
    },
    interfaceLanguageList : ['fr', 'en'],
    language              : '',
    safeView              : false,
    global                : {
      request_seller : false,
      request_buyer  : false
    }
  },
  mutations : {
    [Types.TOGGLE_SIDEBAR](state) {

      if (state.sidebar.opened) {
        Cookies.set('sidebarStatus', 1);
      } else {
        Cookies.set('sidebarStatus', 0);
      }

      state.sidebar.opened = !state.sidebar.opened;
    },
    [Types.CHANGE_LAYOUT](state, layout) {

      state.language = Cookies.get('language');

      if (!Cookies.get('language')) {

        state.language = 'en';
        Cookies.set('language', state.language);
      }

      state.layout = layout;
    },
    [Types.SELECT_TAB](state, { page, tab }) {

      state.tab[page] = tab;
    },
    [Types.UPDATE_LANGUAGE](state, { lang }) {

      if (lang !== undefined) {

        state.language = lang;
        Cookies.set('language', lang);

        moment.locale(lang);
      }
    },
    [Types.SWITCH_SAFEVIEW](state) {

      state.safeView = !state.safeView;
    },
    [Types.SWITCH_GLOBAL](state, { name }) {

      state.global[name] = !state.global[name];
    }
  },
  actions   : {
    toggleSideBar({ commit }) {

      commit(Types.TOGGLE_SIDEBAR);
    },
    changeLayout({ commit }, layout) {

      if (layout && this.state.app.layout !== layout) {

        commit(Types.CHANGE_LAYOUT, layout);
      }
    },
    selectTab({ commit }, { page, tab }) {

      if (tab !== this.state.app.tab[page]) {
        commit(Types.SELECT_TAB, { page, tab });
      }
    },
    setLanguage({ commit }, { lang }) {

      commit(Types.UPDATE_LANGUAGE, { lang });
    },
    switchSafeView({ commit }) {

      commit(Types.SWITCH_SAFEVIEW);
    },
    switchGlobal({ commit }, { name }) {

      commit(Types.SWITCH_GLOBAL, { name });
    }
  },
  getters   : {
    sidebar               : state => state.sidebar,
    layout                : state => state.layout,
    tab                   : state => state.tab,
    language              : state => state.language,
    interfaceLanguageList : state => state.interfaceLanguageList,
    safeView              : state => state.safeView,
    global                : state => state.global,
    alert                 : state => state.alert
  }
};

export default app;
