import { getRule }        from '@/utils/Helpers/portfolioHelper';
import ObjectManipulation from '@/utils/object';
import store              from '@/store';
import RBAC               from '@pixul/hestia-rbac';
import * as Types         from '../mutationTypes';

// initial state
const state = {
  mainRules     : {
    from : {
      mandate     : {
        owner        : 'coOwner',
        coOwner      : 'coOwner',
        collaborator : 'collaborator'
      },
      property    : {
        owner        : 'coOwner',
        coOwner      : 'coOwner',
        collaborator : 'collaborator'
      },
      commissions : ''
    },
    to   : {
      mandate     : {
        owner        : 'owner',
        coOwner      : 'coOwner',
        collaborator : 'collaborator'
      },
      property    : {
        owner        : 'owner',
        coOwner      : 'coOwner',
        collaborator : 'collaborator'
      },
      commissions : ''
    }
  },
  specificRules : [],
  transfer      : {},
  resources     : {},
  usersRoles    : {}
};

// getters
const getters = {
  mainRules     : state => state.mainRules,
  specificRules : state => state.specificRules,
  transfer      : state => state.transfer,
  resources     : state => state.resources,
  usersRoles    : state => state.usersRoles //Used to save user resource role
};

// actions
const actions = {

  initPortfolioTransfer({ commit }) {

    commit(Types.INIT_PORTFOLIO_TRANSFER);
  },
  setPortfolioTransferInfos({ commit }, { transfer }) {

    commit(Types.SET_TRANSFER_INFOS, transfer);
  },
  setPortfolioResources({ commit }, { resources }) {

    commit(Types.SET_RESOURCES, resources);
  },
  updateResourceAgent({ commit }, { resourceId, newUserId }) {

    commit(Types.UPDATE_RESOURCE_AGENT, { resourceId, newUserId });
  },
  updateMultipleResourcesAgent({ commit }, { resourceIds, newUserId }) {

    commit(Types.UPDATE_MULTIPLE_RESOURCE_AGENT, { resourceIds, newUserId });
  },
  setPortfolioMainRules({ commit }, { mainRules }) {

    commit(Types.SET_MAIN_RULES, mainRules);
  },
  changePortfolioSpecificRule({ commit, state }, { newRole, type, userType, role, userId, resourceId = null }) {

    // Test if a rule already exist, if the case, we need to splice it
    const specificRuleToResourceIndex = state.specificRules.findIndex(r => {

      return (
        r.type === type &&
        r.userId === userId &&
        r.userType === userType &&
        r.role === role &&
        r.resourceId === resourceId
      );
    });

    if (specificRuleToResourceIndex !== -1) {

      commit(Types.REMOVE_SPECIFIC_RULE, specificRuleToResourceIndex);
    }

    const currentRole = getRule(type, userType, role, userId, resourceId);

    if (currentRole !== newRole) {

      commit(Types.ADD_SPECIFIC_RULE, { type, userId, userType, role, newRole, resourceId });
    }
  },

  // USER ROLE
  async setAllUserResourceRole({ commit, state }, { tenantId, users }) {

    const usersRoles = {};

    for (const userId of users) {

      const user = await store.cache.dispatch('getUser', { tenantId : tenantId, id : userId });
      usersRoles[userId] = {};

      state.resources.forEach(r => {

        const roles = RBAC.getRolesForUser(user, r.data);

        usersRoles[userId][r.data.id] = ['owner', 'coOwner', 'collaborator'].find(r => roles.includes(r)) || 'guest';
      });
    }

    commit(Types.SET_ALL_USER_RESOURCE_ROLE, usersRoles);
  },

  // Status
  updatePortfolioTransferStatus({ commit }, status) {

    commit(Types.UPDATE_PORTFOLIO_TRANSFER_STATUS, status);
  }
};

// mutations
const mutations = {
  [Types.INIT_PORTFOLIO_TRANSFER](state) {

    state.transfer = {
      type      : 'complete', // complete or partial
      agentFrom : '',
      agentsTo  : [],
      partial   : {
        properties : [],
        mandates   : []
      },
      users     : {},
      status    : 'settings'
    };
    state.usersRoles = {};
    state.specificRules = [];
    state.mainRules = {
      from : {
        mandate     : {
          owner        : 'coOwner',
          coOwner      : 'coOwner',
          collaborator : 'collaborator'
        },
        property    : {
          owner        : 'coOwner',
          coOwner      : 'coOwner',
          collaborator : 'collaborator'
        },
        commissions : ''
      },
      to   : {
        mandate     : {
          owner        : 'owner',
          coOwner      : 'coOwner',
          collaborator : 'collaborator'
        },
        property    : {
          owner        : 'owner',
          coOwner      : 'coOwner',
          collaborator : 'collaborator'
        },
        commissions : ''
      }
    };
  },
  [Types.SET_TRANSFER_INFOS](state, transfer) {

    state.transfer = transfer;
  },
  [Types.SET_RESOURCES](state, resources) {

    state.resources = resources;
  },
  [Types.UPDATE_RESOURCE_AGENT](state, { resourceId, newUserId }) {

    const index = state.resources.findIndex(r => r.data.id === resourceId);

    if (index !== -1) {

      const resource = state.resources[index];

      resource.transfer.agentTo = newUserId;
      resource.transfer.currentRoleTo = newUserId ? state.usersRoles[newUserId][resource.data.id] : null;

      state.resources[index] = resource;
    }
  },
  [Types.UPDATE_MULTIPLE_RESOURCE_AGENT](state, { resourceIds, newUserId }) {

    const resources = ObjectManipulation.clone(state.resources);

    resourceIds.forEach(resourceId => {

      const index = state.resources.findIndex(r => r.data.id === resourceId);

      if (index !== -1) {

        const resource = resources[index];

        resource.transfer.agentTo = newUserId;
        resource.transfer.currentRoleTo = newUserId ? state.usersRoles[newUserId][resource.data.id] : null;

        resources[index] = resource;
      }
    });

    state.resources = resources;

  },
  [Types.SET_MAIN_RULES](state, mainRules) {

    state.mainRules = mainRules;
  },
  [Types.REMOVE_SPECIFIC_RULE](state, index) {

    state.specificRules.splice(index, 1);
  },
  [Types.ADD_SPECIFIC_RULE](state, { type, userId, userType, role, newRole, resourceId = null }) {

    state.specificRules.push({
      type       : type,
      userId     : userId,
      userType   : userType,
      resourceId : resourceId,
      role       : role,
      newRole    : newRole
    });
  },
  [Types.SET_ALL_USER_RESOURCE_ROLE](state, usersRoles) {

    state.usersRoles = usersRoles;

    // Also set currentRoleFrom / currentRoleTo for each resource
    state.resources = state.resources.map(r => {

      r['transfer']['currentRoleFrom'] = usersRoles[state.transfer.agentFrom][r.data.id];
      r['transfer']['currentRoleTo'] = usersRoles[r.transfer.agentTo][r.data.id];

      return r;
    });

  },

  [Types.UPDATE_PORTFOLIO_TRANSFER_STATUS](state, status) {

    state.transfer.status = status;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
