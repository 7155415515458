import Language from '@pixul/languages';
import Store    from '@/store';

export default (code) => {

  if (code !== null && code !== undefined) {

    return Language.get(code, Store.state.app.language);
  }

  return '';
};


