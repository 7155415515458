import { confirmCloseDialog, confirmDelete, confirmUndelete, customConfirm, i18nMessage } from '@/utils/message';
import { convertDateToLocal, convertDateToUtc }                                           from '@/utils/date';

const GlobalFunctionPlugin = {
  install(Vue) {

    Vue.prototype.$convertDateToUtc = convertDateToUtc;

    Vue.prototype.$convertDateToLocal = convertDateToLocal;

    Vue.prototype.$i18nMessage = i18nMessage;

    Vue.prototype.$customConfirm = customConfirm;
    Vue.prototype.$confirmDelete = confirmDelete;
    Vue.prototype.$confirmUndelete = confirmUndelete;
    Vue.prototype.$confirmCloseDialog = confirmCloseDialog;

    Vue.capitalize = function (text) {

      return text.charAt(0).toUpperCase() + text.slice(1);
    };

    Vue.prototype.$lightOrDark = function (color, format = 'name') {

      // Variables for red, green, blue values
      let r,
        g,
        b;

      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {

        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        r = color[1];
        g = color[2];
        b = color[3];
      } else {

        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +('0x' + color.slice(1).replace(
          color.length < 5 && /./g, '$&$&'));

        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      if (hsp > 127.5) {

        return format === 'name' ? 'light' : '000000';
      }

      return format === 'name' ? 'dark' : 'FFFFFF';

    };

    Vue.prototype.$hashCode = function (str) {

      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }

      return hash;
    };

    Vue.prototype.$intToRGB = function (i) {

      let color = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

      color = adjustColorBrightness('00000'.substring(0, 6 - color.length) + color);
      return color;
    };

    Vue.prototype.$setPageTitle = function (title) {

      document.title = title;
    };
  }
};

// Prend une valeur Hexa en entrée et ajuste le retour pour corriger des soucis de vision de couleurs
function adjustColorBrightness(color) {
    // Convert hex color to RGB
  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);

  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Si la brightness est trop grande l'icone risque de ne pas etre visible, on noirci donc la couleur
  if (brightness > 210) {
    const darkerR = Math.floor(r * (100 - 5) / 100);
    const darkerG = Math.floor(g * (100 - 5) / 100);
    const darkerB = Math.floor(b * (100 - 5) / 100);

    return '#' + ('0' + darkerR.toString(16)).slice(-2) +
            ('0' + darkerG.toString(16)).slice(-2) +
            ('0' + darkerB.toString(16)).slice(-2);
  }

  return color;
}

export default GlobalFunctionPlugin;
