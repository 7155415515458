import store from '@/store';

let interval;

function refreshNotifications() {

  interval = setInterval(() => {

    store.dispatch('getNotificationsUnread', {
      tenantId : store.getters.user.tenantId
    });
  }, 60000);

}

export function changeNotificationCheck(enabled) {

  if (enabled && !interval) {
    refreshNotifications();
  } else if (!enabled && interval) {
    clearInterval(interval);
  }
}
