<template>
  <div id="app">
    <Alert />
    <VersionAlert />
    <Transition name="fade">
      <Component :is="layout" />
    </Transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { computed }   from 'vue';
import AdminLayout    from '@/views/layouts/Admin/index.vue';
import DefaultLayout  from '@/views/layouts/Default/index.vue';
import SimpleLayout   from '@/views/layouts/Simple/index.vue';
import VersionAlert   from '@/components/Views/VersionAlert.vue';
import Alert          from '@/components/Views/Alert.vue';

export default {
  name       : 'App',
  components : {
    'default' : DefaultLayout,
    'admin'   : AdminLayout,
    'simple'  : SimpleLayout,
    Alert,
    VersionAlert
  },
  provide() {

    return {

      container : computed(() => document.getElementById('app'))
    };
  },
  computed : {
    ...mapGetters(['layout'])
  }
};
</script>
