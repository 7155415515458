import * as Types from '../mutationTypes';
import HestiaApi  from '@/api';

// initial state
const state = {
  eligibilities : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  }
};

// getters
const getters = {
  eligibilities : state => state.eligibilities
};

// actions
const actions = {
  // ELIGIBILITY
  async getEligibilities({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Commercialisation.eligibility.list(tenantId, query).json();
    commit(Types.RECEIVE_ELIGIBILITIES, response);
  },
  async addEligibility({ commit }, { tenantId, eligibility }) {

    const response = await HestiaApi.Commercialisation.eligibility.create(tenantId, eligibility).json();
    commit(Types.ADD_ELIGIBILITY, response);
  },
  async editEligibility({ commit }, { id, eligibility, tenantId }) {

    const response = await HestiaApi.Commercialisation.eligibility.update(tenantId, id, eligibility).json();
    commit(Types.EDIT_ELIGIBILITY, response);
  },
  async removeEligibility({ commit }, { tenantId, eligibilityId }) {

    await HestiaApi.Commercialisation.eligibility.delete(tenantId, eligibilityId);
    commit(Types.REMOVE_ELIGIBILITY, eligibilityId);
  },
  async getEligibility({ commit }, { tenantId, eligibilityId }) {

    const response = await HestiaApi.Commercialisation.eligibility.get(tenantId, eligibilityId).json();
    commit(Types.RECEIVE_ELIGIBILITY, response);
  }
};

// mutations
const mutations = {
  [Types.RECEIVE_ELIGIBILITIES](state, eligibilities) {

    state.eligibilities = eligibilities;
  },
  [Types.ADD_ELIGIBILITY](state, eligibility) {

    state.eligibilities.results.push(eligibility);
  },
  [Types.EDIT_ELIGIBILITY](state, eligibility) {

    const index = state.eligibilities.results.findIndex((el) => el.id === eligibility.id);
    state.eligibilities.results.splice(index, 1, eligibility);
  },
  [Types.REMOVE_ELIGIBILITY](state, eligibilityId) {

    const index = state.eligibilities.results.findIndex((e) => e.id === eligibilityId);
    state.eligibilities.results.splice(index, 1);
  }

};

export default {
  state,
  getters,
  actions,
  mutations
};
