import * as Types from '../mutationTypes';

import HestiaApi from '@/api';

// initial state
const state = {
  audit_events : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  }
};

// getters
const getters = {
  audit_events : state => state.audit_events
};

// actions
const actions = {
  async getAuditEvents({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Audit.audit.search(tenantId, query).json();

    commit(Types.RECEIVE_AUDIT_EVENTS, response);
  }
};

// mutations
const mutations = {
  [Types.RECEIVE_AUDIT_EVENTS](state, audit_events) {

    state.audit_events = audit_events;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
