<template>
  <div
    :class="{ hideSidebar:!sidebar.opened }"
    class=""
  >
    <Sidebar class="sidebar-container" />
    <div class="main-container">
      <AppMain />
      <NavFooter />
    </div>
  </div>
</template>

<script>

export default {
  name       : 'Layout',
  components : {
    Sidebar   : () => import('./components/Sidebar/index.vue'),
    AppMain   : () => import('./components/AppMain.vue'),
    NavFooter : () => import('./components/NavFooter.vue')
  },
  computed   : {
    sidebar() {

      return this.$store.state.app.sidebar;
    }

  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

@import '../../../styles/sidebar.scss';

.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

</style>
