import * as Types from '../mutationTypes';
import HestiaApi  from '@/api';

// initial state
const state = {
  partners          : {
    results : []
  },
  currentPartner    : {},
  partnerProperties : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  }
};

// getters
const getters = {
  partners          : state => state.partners,
  currentPartner    : state => state.currentPartner,
  partnerProperties : state => state.partnerProperties
};

// actions
const actions = {
  async getPartners({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Partner.partner.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_PARTNERS, response);
  },
  async addPartner({ commit }, { tenantId, partner }) {

    const response = await HestiaApi.Partner.partner.create(tenantId, partner).json();

    commit(Types.ADD_PARTNER, response);
    return response;
  },
  async selectPartner({ commit }, { tenantId, id }) {

    const response = await HestiaApi.Partner.partner.get(tenantId, id).json();

    commit(Types.SELECT_PARTNER, response);
  },
  async getPartnerProperties({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Partner.property.list(tenantId, { searchParams : query }).json();

    commit(Types.RECEIVE_PARTNER_PROPERTIES, response);
  },
  async editPartner({ commit }, { tenantId, id, partner }) {

    const response = await HestiaApi.Partner.partner.update(tenantId, id, partner).json();

    commit(Types.EDIT_PARTNER, response);
  },
  async editPartnerDocument({ commit }, { tenantId, id, partner }) {

    const response = await HestiaApi.Partner.partner.update(tenantId, id, partner).json();

    commit(Types.EDIT_PARTNER_DOCUMENT, response);
  },
  async removePartnerDocument({ commit }, { tenantId, id, documentId }) {

    const index = state.currentPartner.documents.findIndex(docId => docId === documentId);

    state.currentPartner.documents.splice(index, 1);

    const partner = {
      documents : state.currentPartner.documents
    };

    const response = await HestiaApi.Partner.partner.update(tenantId, id, partner).json();

    commit(Types.PARTNER_DELETE_DOCUMENT, response);
  },
  async editPartnerOwner({ commit }, { tenantId, id, owner, removeOwnerFrom = false, previousOwner = false }) {

    const partner = {
      owner : owner.id
    };

    const response = await HestiaApi.Partner.partner.update(tenantId, id, partner).json();

    commit(Types.EDIT_PARTNER, response);

    if (removeOwnerFrom === 'coOwner') {

      this.dispatch('removePartnerCoowner', { tenantId, id, coownerId : owner.id });
    } else if (removeOwnerFrom === 'collaborator') {

      this.dispatch('removePartnerCollaborator', { tenantId, id, collaboratorId : owner.id });
    }

    if (owner.movePreviousTo === 'coOwner') {

      const coowner = {
        id : previousOwner
      };

      this.dispatch('addPartnerCoowner', { tenantId, id, coowner : coowner });
    } else if (owner.movePreviousTo === 'collaborator') {

      const colab = {
        id : previousOwner
      };

      this.dispatch('addPartnerCollaborator', { tenantId, id, collaborator : colab });
    }

  },
  async addPartnerCoowner({ commit }, { tenantId, id, coowner }) {

    const partner = {
      coOwners : []
    };

    if (state.currentPartner.coOwners.length > 0) {

      partner.coOwners = state.currentPartner.coOwners;
    }

    partner.coOwners.push({
      id      : coowner.id,
      managed : false
    });

    const indexCollaborator = state.currentPartner.collaborators.findIndex((c) => c.id === coowner.id);
    if (indexCollaborator !== -1) {

      partner.collaborators = state.currentPartner.collaborators;
      partner.collaborators.splice(indexCollaborator, 1);
    }

    const response = await HestiaApi.Partner.partner.update(tenantId, id, partner).json();

    commit(Types.EDIT_PARTNER, response);

  },
  async removePartnerCoowner({ commit }, { tenantId, id, coowner }) {

    const listCoOwner = state.currentPartner.coOwners;

    const index = listCoOwner.findIndex((c) => c.id === coowner.id);

    //Suppression du user
    listCoOwner.splice(index, 1);

    const partner = {
      coOwners : listCoOwner
    };

    const response = await HestiaApi.Partner.partner.update(tenantId, id, partner).json();

    commit(Types.EDIT_PARTNER, response);

  },
  async addPartnerCollaborator({ commit }, { tenantId, id, collaborator }) {

    const partner = {
      collaborators : []
    };

    if (state.currentPartner.collaborators.length > 0) {

      partner.collaborators = state.currentPartner.collaborators;
    }

    partner.collaborators.push({
      id      : collaborator.id,
      managed : false
    });

    const indexCoOwner = state.currentPartner.coOwners.findIndex((c) => c.id === collaborator.id);
    if (indexCoOwner !== -1) {

      partner.coOwners = state.currentPartner.coOwners;
      partner.coOwners.splice(indexCoOwner, 1);
    }

    const response = await HestiaApi.Partner.partner.update(tenantId, id, partner).json();

    commit(Types.EDIT_PARTNER, response);
  },
  async removePartnerCollaborator({ commit }, { tenantId, id, collaborator }) {

    const listCollaborator = state.currentPartner.collaborators;

    const index = listCollaborator.findIndex((c) => c.id === collaborator.id);

    //Suppression du user
    listCollaborator.splice(index, 1);

    const partner = {
      collaborators : listCollaborator
    };

    const response = await HestiaApi.Partner.partner.update(tenantId, id, partner).json();

    commit(Types.EDIT_PARTNER, response);
  },
  async importPartnerProperties({ commit }, { tenantId, id, data, params }) {

    const response = await HestiaApi.Partner.property.import(tenantId, id, data, { searchParams : params }).json();

    commit(Types.IMPORT_PARTNER_PROPERTY, response);
  }
};

// mutations
const mutations = {
  [Types.RECEIVE_PARTNERS](state, partners) {

    state.partners.results = partners;
  },
  [Types.ADD_PARTNER](state, partners) {

    state.partners.results.push(partners);
  },
  [Types.SELECT_PARTNER](state, partner) {

    state.currentPartner = partner;
  },
  [Types.RECEIVE_PARTNER_PROPERTIES](state, partnerProperties) {

    state.partnerProperties = partnerProperties;
  },
  [Types.EDIT_PARTNER](state, partner) {

    const index = state.partners.results.findIndex((part) => part.id === partner.id);

    state.partners.results.splice(index, 1, partner);

    state.currentPartner = {
      ...partner
    };
  },
  [Types.EDIT_PARTNER_DOCUMENT](state, partner) {

    const index = state.partners.results.findIndex((part) => part.id === partner.id);
    state.partners.results.splice(index, 1, partner);

    state.currentPartner.documents = partner.documents;
  },
  [Types.IMPORT_PARTNER_PROPERTY](state, property) {

    const index = state.partnerProperties.results.findIndex((part) => part.id === property.id);

    state.partnerProperties.results.splice(index, 1, property);

  },
  [Types.PARTNER_DELETE_DOCUMENT](state, partner) {

    state.currentPartner = partner;

  }

};

export default {
  state,
  getters,
  actions,
  mutations
};
