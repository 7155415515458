import moment from 'moment';

export function convertDateToUtc(date) {

  return moment(date).utc();
}

export function convertDateToLocal(date) {

  return moment.utc(date).local();
}

