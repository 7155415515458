import * as Types from '../mutationTypes';

import { changeNotificationCheck } from '@/utils/notification';
import HestiaApi                   from '@/api';

import JwtDecode                 from 'jwt-decode';
import Router                    from '@/router';
import Store                     from '@/store';
import i18n                      from '@/lang/index';
import { axios }                 from '@bundled-es-modules/axios';
import Config                    from '@/config';
import * as Sentry               from '@sentry/vue';
import { Posthog, registerUser } from '@/plugins/Posthog';

// initial state
const state = {
  token : false,
  user  : false
};

// getters
const getters = {
  token : state => state.token,
  user  : state => state.user
};

// actions
const actions = {
  async getToken({ commit }, { tenantId, code }) {

    const response = await HestiaApi.Authentication.authentication.token(tenantId, { searchParams : { code } }).json();

    commit(Types.RECEIVE_TOKEN, response.token);
  },
  async login({ commit }, { tenantId, email, password }) {

    const response = await HestiaApi.Authentication.authentication.login(tenantId, { email, password }).json();

    commit(Types.RECEIVE_TOKEN, response.token);

    const user = JwtDecode(response.token);

    setTimeout(() => {

      commit(Types.LOGOUT);
      return Router.push({
        name   : 'login',
        params : {
          id        : tenantId,
          errorCode : 'session timeout'
        }
      });

    }, (user.exp - (new Date).getTime() / 1000) * 1000);
  },
  async azureLogin({ commit }, { tenantId, code, state }) {

    try {

      const { data : response } = await axios.get(`${ Config.baseURL }/tenant/${ tenantId }/login/azure?code=${ code }&state=${ state }`, {
        withCredentials : true
      });

      commit(Types.RECEIVE_TOKEN, response.token);

      const user = JwtDecode(response.token);

      setTimeout(() => {

        commit(Types.LOGOUT);

        return Router.push({
          name   : 'login',
          params : {
            id        : tenantId,
            errorCode : 'Session timeout, please reconnect you.'
          }
        });

      }, (user.exp - (new Date).getTime() / 1000) * 1000);

    } catch (e) {

      return Router.push({
        name   : 'login',
        params : {
          id        : tenantId,
          errorCode : 'An error occurred'
        }
      });
    }
  },
  async googleLogin({ commit }, { tenantId, code, state, scope }) {

    try {

      const { data : response } = await axios.get(`${ Config.baseURL }/tenant/${ tenantId }/login/google?code=${ code }&state=${ state }&scope=${ scope }`, {
        withCredentials : true
      });

      commit(Types.RECEIVE_TOKEN, response.token);

      const user = JwtDecode(response.token);

      setTimeout(() => {

        commit(Types.LOGOUT);

        return Router.push({
          name   : 'login',
          params : {
            id        : tenantId,
            errorCode : 'Session timeout, please reconnect you.'
          }
        });

      }, (user.exp - (new Date).getTime() / 1000) * 1000);

    } catch (e) {
      return Router.push({
        name   : 'login',
        params : {
          id        : tenantId,
          errorCode : 'An error occurred'
        }
      });
    }
  },
  checkLogout({ commit }) {

    const currentTime = new Date().getTime() / 1000;

    if (currentTime > Store.getters.user.exp) {

      commit(Types.LOGOUT);

      return Router.push({
        name   : 'login',
        params : {
          id        : Store.getters.tenant.id,
          errorCode : 'Session timeout, please reconnect you.'
        }
      });
    }
  },
  async logout({ commit }) {

    commit(Types.LOGOUT);

    return Router.push({
      name   : 'login',
      params : {
        tenant : Store.getters.tenant.id
      }
    });
  }
};

// mutations
const mutations = {
  async [Types.RECEIVE_TOKEN](state, token) {

    state.token = token;
    state.user = JwtDecode(token);

    state.user = await HestiaApi.User.user.get(state.user.tenantId, state.user.id).json();

    Sentry.setUser({
      id       : state.user.id,
      email    : state.user.email,
      username : `${ state.user.firstName } ${ state.user.lastName }`
    });

    changeNotificationCheck(true);

    registerUser(state.user);
  },
  [Types.LOGOUT](state) {

    if (state.user.options && state.user.options.preferredLanguage) {
      i18n.locale = state.user.options.preferredLanguage;
      Store.dispatch('setLanguage', { lang : state.user.options.preferredLanguage });
    }

    Sentry.getCurrentScope().setUser(null);

    if (Posthog && Posthog.reset && Posthog.persistence) {
      Posthog.reset();
    }

    state.user = false;
    state.token = false;

    changeNotificationCheck(false);

    Store.dispatch('clearBookmarks');

    state.menu_routes = {
      'default' : [],
      'admin'   : [],
      'simple'  : []
    };
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
