import * as Types from '../mutationTypes';
import HestiaApi  from '@/api';

// initial state
const state = {
  notifications       : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  },
  notificationsUnread : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  },
  schedules           : {
    results  : [],
    selected : {}
  }
};

// getters
const getters = {
  notifications       : state => state.notifications,
  notificationsUnread : state => state.notificationsUnread,
  schedules           : state => state.schedules
};

const helpers = {

  readNotification : (notification) => {

    if (state.notificationsUnread.results.length !== 0) {
      const index = state.notificationsUnread.results.findIndex((n) => n.id === notification.id);
      if (index !== -1) {
        state.notificationsUnread.results[index].read   = true;
        state.notificationsUnread.results[index].readAt = notification.readAt;
        state.notificationsUnread.results.splice(index, 1);
      }
    }

    if (state.notifications.results.length !== 0) {

      const index = state.notifications.results.findIndex((n) => n.id === notification.id);
      if (index !== -1) {

        state.notifications.results.splice(index, 1, notification);
      }
    }
  }
};

// actions
const actions = {
    // NOTIFICATION
  async getNotifications({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Notification.notification.list(tenantId, { searchParams : { ...query, category : 'hestia' } }).json();
    commit(Types.RECEIVE_NOTIFICATIONS, response);
  },
    // NOTIFICATION
  async getNotificationsUnread({ commit }, { tenantId }) {

    const response = await HestiaApi.Notification.notification.list(tenantId, { searchParams : { 'read:eq' : false, category : 'hestia' } }).json();
    commit(Types.RECEIVE_NOTIFICATIONS_UNREAD, response);
  },

  async readNotification({ commit }, { tenantId, id, ...rest }) {

    const response = await HestiaApi.Notification.notification.read(tenantId, id).json();
    commit(Types.READ_NOTIFICATION, { ...rest, ...response });
  },

  async readBatchNotifications({ commit }, { tenantId, json }) {

    const response = await HestiaApi.Notification.notification.batchRead(tenantId, json).json();
    commit(Types.READ_NOTIFICATIONS, response);
  },

  async readAllNotifications({ commit }, { tenantId }) {

    const response = await HestiaApi.Notification.notification.readAll(tenantId, {}, { searchParams : { category : 'hestia' } }).json();
    commit(Types.READ_NOTIFICATIONS, response);
  },

  async unreadNotification({ commit }, { tenantId, id, ...rest }) {

    const response = await HestiaApi.Notification.notification.unread(tenantId, id).json();
    commit(Types.UNREAD_NOTIFICATION, { ...rest, ...response });
  },
    // SCHEDULE
  async getSchedules({ commit }, { tenantId, receiverId, query }) {

    const response = await HestiaApi.Notification.schedule.listForReceiver(tenantId, receiverId, { searchParams : query }).json();
    commit(Types.RECEIVE_SCHEDULES, response);
  },
  async getScheduleType({ commit }, { tenantId, receiverId, type }) {

    const response = await HestiaApi.Notification.schedule.getForReceiver(tenantId, type, receiverId).json();
    commit(Types.GET_SCHEDULE_TYPE, response);
  },
  async updateScheduleType({ commit }, { tenantId, receiverId, type, schedule }) {

    const response = await HestiaApi.Notification.schedule.updateForReceiver(tenantId, type, receiverId, schedule).json();
    commit(Types.EDIT_SCHEDULE_TYPE, response);
  }
};

// mutations
const mutations = {
  [Types.RECEIVE_NOTIFICATIONS](state, notifications) {

    state.notifications = notifications;
  },
  [Types.RECEIVE_NOTIFICATIONS_UNREAD](state, notifications) {

    state.notificationsUnread = notifications;
  },

  [Types.READ_NOTIFICATION](state, notification) {

    helpers.readNotification(notification);
    state.notificationsUnread.pagination.total--;
  },

  [Types.READ_NOTIFICATIONS](state, notifications) {

    for (const notification of notifications) {

      helpers.readNotification(notification);
    }

    state.notificationsUnread.pagination.total-= notifications.length;
  },

  [Types.UNREAD_NOTIFICATION](state, notification) {

    if (state.notifications.results.length !== 0) {

      const index = state.notifications.results.findIndex((n) => n.id === notification.id);
      if (index !== -1) {

        state.notifications.results.splice(index, 1, notification);
      }
    }
  },

    // SCHEDULES
  [Types.RECEIVE_SCHEDULES](state, schedules) {

    state.schedules.results = schedules;
  },
  [Types.GET_SCHEDULE_TYPE](state, schedule) {

    state.schedules.selected = schedule;
  },
  [Types.EDIT_SCHEDULE_TYPE](state, schedule) {

    state.schedules.results.find(sch => sch.type === schedule.type);
    const index = state.schedules.results.findIndex(sch => sch.type === schedule.type);
    state.schedules.results.splice(index, 1, schedule);

    state.schedules.selected = schedule;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

