import * as Types from '../mutationTypes';
import HestiaApi  from '@/api';

// initial state
const state = {
  timemachines     : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  },
  timemachine_user : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  }
};

// getters
const getters = {
  timemachines     : state => state.timemachines,
  timemachine_user : state => state.timemachine_user
};

// actions
const actions = {
  // ALL MANDATES
  async getTimeMachines({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Timemachine.timecapsule.list(tenantId, { searchParams : query }).json();

    commit(Types.RECEIVE_TIMECAPSULES, response);
  },
  // BY USER
  async getTimeMachinesUser({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Timemachine.timecapsule.me(tenantId, { searchParams : query }).json();

    commit(Types.RECEIVE_TIMECAPSULES_USER, response);
  }
};

// mutations
const mutations = {
  [Types.RECEIVE_TIMECAPSULES](state, timemachines) {

    state.timemachines = timemachines;
  },
  [Types.RECEIVE_TIMECAPSULES_USER](state, timemachinesUser) {

    state.timemachine_user = timemachinesUser;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
