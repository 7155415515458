import * as Types from '../mutationTypes';

// initial state
const state = {
  map : {
    durations : []
  }
};

// getters
const getters = {
  durations : state => state.map.durations
};

// actions
const actions = {

  addDuration({ commit }, { origin, destination, duration }) {

    commit(Types.ADD_DURATION, { origin, destination, duration });
  }
};

// mutations
const mutations = {
  [Types.ADD_DURATION](state, { origin, destination, duration }) {

    state.map.durations.push({
      origin      : origin,
      destination : destination,
      duration    : duration
    });
  }

};

export default {
  state,
  getters,
  actions,
  mutations
};
