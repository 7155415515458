import ObjectUtils        from '@/utils/object';
import ObjectManipulation from '@/utils/object';

export default class Model {

  static get model() {

    return {};
  }

  static get default() {

    return ObjectManipulation.clone(this.model);
  }

  static get rules() {

    return {};
  }

  static omitRules(...items) {

    return ObjectUtils.omit(this.rules, items);
  }

  static pickRules(...items) {

    return ObjectUtils.pick(this.rules, items);
  }

  static omitFields(...items) {

    return ObjectUtils.omit(this.form, items);
  }

  static pickFields(items) {

    return this.form.filter((field) => {

      return items.includes(field.$id);
    });
  }

  static hookFormRules(form) {

    return form;
  }
}

