import Vue                  from 'vue';
import Vuex                 from 'vuex';
import createLogger         from 'vuex/dist/logger';
import createPersistedState from 'vuex-persistedstate';
import createCache          from 'vuex-cache';

const context = import.meta.glob('./modules/*.js', { eager : true });
const modules = {};

for (const path in context) {
  modules[path.match(/.\/(\w+).js/)[1]] = context[path].default;
}

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules,
  strict  : false, //debug,
  plugins : [
    debug ? createLogger() : () => {},
    createCache(),
    createPersistedState({
      paths : ['app', 'auth', 'tenant', 'route', 'calendar', 'bookmark', 'favoriteClient', 'favoriteThreadTemplate']
    })
  ]
});
