import Store from '@/store/index.js';

export default {
  methods : {
    routeWithTenant({ name, path, params = {} }) {

      return {
        name   : name || '',
        path   : path || '',
        params : {
          ...params,
          tenant : Store.getters.tenant.id
        }
      };
    }
  }
};
