import * as Types from '../mutationTypes';
import HestiaApi  from '@/api';

// initial state
const state = {
  clientsFavorite : []
};

// getters
const getters = {
  clientsFavorite : state => state.clientsFavorite
};

// actions
const actions = {
  async getClientsFavorite({ commit }, { tenantId }) {

    const response = await HestiaApi.Client.favorite.list(tenantId).json();

    commit(Types.RECEIVE_CLIENTS_FAVORITE, response);
  },
  async addClientFavorite({ commit }, { tenantId, clientId }) {

    const response = await HestiaApi.Client.favorite.add(tenantId, clientId).json();

    commit(Types.ADD_CLIENT_FAVORITE, response);
  },
  async removeClientFavorite({ commit }, { tenantId, clientId }) {

    await HestiaApi.Client.favorite.remove(tenantId, clientId);

    commit(Types.REMOVE_CLIENT_FAVORITE, clientId);
  }

};

// mutations
const mutations = {
  [Types.RECEIVE_CLIENTS_FAVORITE](state, clientsFavorite) {

    state.clientsFavorite = clientsFavorite;
  },
  [Types.ADD_CLIENT_FAVORITE](state, clientsFavorite) {

    state.clientsFavorite = clientsFavorite;
  },
  [Types.REMOVE_CLIENT_FAVORITE](state, clientId) {

    const index = state.clientsFavorite.findIndex((client) => client.clientId === clientId);
    state.clientsFavorite.splice(index, 1);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
