<template>
  <div
    v-if="tenant && tenant.options && tenant.options.alert && tenant.options.alert.active"
    :class="cssClass"
    class="alert-wrapper"
  >
    <div class="app-wrapper">
      {{ tenant.options.alert.message }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name : 'Alert',
  data() {

    return {
      cssClass    : '',
      timerId     : undefined,
      levelColors : {
        info    : 'info',
        warning : 'warning',
        alert   : 'alert'
      }
    };

  },
  computed : {
    ...mapGetters(['user', 'tenant'])
  },

  watch : {
    tenant : {
      handler() {

        if (this.tenant.options && this.tenant.options.alert) {
          this.colorAlert(this.tenant.options.alert.level);
        }
      }, deep : true
    }
  },

  async created() {

    if (this.tenant.options && this.tenant.options.alert) {
      this.colorAlert(this.tenant.options.alert.level);
    }

    this.timerId = setInterval(this.updateTenant, 300000);
  },
  beforeDestroy() {

    if (this.timerId) {
      clearInterval(this.timerId);
    }
  },
  methods : {
    colorAlert(level) {

      if (this.levelColors[level]) {
        this.cssClass = this.levelColors[level];
      }
    },
    updateTenant() {

      if (!this.user || !this.user.tenantId) {
        return;
      }

      this.$store.dispatch('getTenant', { tenantId : this.user.tenantId });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

.info {
  background-color: $hestia-blue
}

.warning {
  background-color: $warning
}

.alert {
  background-color: $danger
}

.alert-wrapper {
  color: white;
  text-align: center;
  font-size: 15px;
  padding: 10px
}
</style>
