import { version } from '../../package.json';

export default {
  title                  : import.meta.env.VITE_APP_TITLE || 'Hestia',
  NODE_ENV               : import.meta.env.VITE_APP_NODE_ENV || 'development',
  environment            : import.meta.env.VITE_APP_ENVIRONMENT || process.env.NODE_ENV || 'development',
  version                : import.meta.env.VITE_APP_VERSION || version,
  baseURL                : import.meta.env.VITE_APP_BASE_API || 'https://api.staging.hestia.immo',
  clientBaseURL          : import.meta.env.VITE_APP_CLIENT_BASE_URL || 'https://client.staging.hestia.immo',
  baseTimout             : import.meta.env.VITE_APP_API_TIMEOUT || 0,
  sentryDsn              : import.meta.env.VITE_APP_SENTRY_DSN || false,
  googleApiKey           : import.meta.env.VITE_APP_GOOGLE_API_KEY || 'AIzaSyBJu8mG0uN9pzWmUtA9Lii8H5MUylUhPAQ',
  mapURL                 : import.meta.env.VITE_APP_MAP_URL || 'https://d2c1d8g259cs0g.cloudfront.net/07-07-2022/{z}/{x}/{y}.pbf',
  pixulName              : import.meta.env.VITE_APP_PIXUL_NAME || 'Pixul',
  pixulAddress           : import.meta.env.VITE_APP_PIXUL_ADDRESS || '22 rue de ribérac',
  pixulPostcode          : import.meta.env.VITE_APP_PIXUL_POSTCODE || '24340',
  pixulTown              : import.meta.env.VITE_APP_PIXUL_TOWN || 'La Rochebeaucourt',
  pixulCountry           : import.meta.env.VITE_APP_PIXUL_COUNTRY || 'France',
  pixulPhone             : import.meta.env.VITE_APP_PIXUL_PHONE || '+33 5 53 60 51 35',
  pixulEmail             : import.meta.env.VITE_APP_PIXUL_EMAIL || 'info@pixul.fr',
  CI                     : import.meta.env.VITE_APP_CI || 'FALSE',
  postHogApiHost         : import.meta.env.POSTHOG_API_HOST || 'https://eu.posthog.com',
  postHogToken           : import.meta.env.POSTHOG_TOKEN || 'phc_j4RigVyDDPdn9xVTtCD8W7NlXWatJslVnIq8VO9j1jS',
  COMS_URL               : import.meta.env.VITE_APP_COMS_URL || 'https://comms.hestia.cool',
  STRIPE_PUBLISHABLE_KEY : import.meta.env.VITE_APP_STRIPE_PUBLISHABLE_KEY || 'pk_test_51LTKNABo5160lpMPLeSoqiRymPePnsVxjvzLF3Hmgh6mfp2sDQwiTaRTH1qKAIUi7CXr4acNcdKbTLGw2y5o1qM800HSv02dvu',
  useLocalBackend        : import.meta.env.VITE_APP_USE_LOCAL_BACKEND || false
};
