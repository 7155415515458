import Model from '../utils/model';
import i18n  from '@/lang'; // Internationalization

export default class Calendar extends Model {

  static get model() {

    return {
      name   : '',
      color  : '', ///^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
      shares : {
        users  : [],
        groups : []
      }
    };
  }

  static get actionByPermissions() {

    return {
      'view'   : { 'edit' : false, 'delete' : false },
      'add'    : { 'edit' : false, 'delete' : false },
      'edit'   : { 'edit' : false, 'delete' : false },
      'manage' : { 'edit' : true, 'delete' : true }
    };
  }

  static get definition() {

    return {

      'name'          : {
        label : 'calendar.fields.name',
        rules : [
          {
            required : true, message  : i18n.t('rules.required', { id : i18n.t('calendar.fields.name') }),
            trigger  : 'blur'
          }
        ]
      },
      'color'         : {
        label : 'calendar.fields.color',
        rules : [
          {
            required : true, message  : i18n.t('rules.required', { id : i18n.t('calendar.fields.color') }),
            trigger  : 'blur'
          }
        ]
      },
      'shares.groups' : {
        label : 'calendar.fields.shares.groups'
      },
      'shares.users'  : {
        label : 'calendar.fields.shares.users'
      }
    };
  }
}
