import * as Types from '../mutationTypes';
import HestiaApi  from '@/api';

// initial state
const state = {
  threadTemplateFavorites : []
};

// getters
const getters = {
  threadTemplateFavorites : state => state.threadTemplateFavorites
};

// actions
const actions = {

  // THREAD_TEMPLATE_FAVORITE
  async getThreadTemplateFavorite({ commit }, { tenantId }) {

    const response = await HestiaApi.Thread.favorite.list(tenantId).json();

    commit(Types.RECEIVE_THREAD_TEMPLATE_FAVORITE, response);
  },
  async addThreadTemplateFavorite({ commit }, { tenantId, threadTemplateId }) {

    const response = await HestiaApi.Thread.favorite.add(tenantId, threadTemplateId).json();

    commit(Types.ADD_THREAD_TEMPLATE_FAVORITE, response);
  },
  async removeThreadTemplateFavorite({ commit }, { tenantId, threadTemplateId }) {

    await HestiaApi.Thread.favorite.remove(tenantId, threadTemplateId);

    commit(Types.REMOVE_THREAD_TEMPLATE_FAVORITE, threadTemplateId);
  }
};

// mutations
const mutations = {
  [Types.RECEIVE_THREAD_TEMPLATE_FAVORITE](state, threadTemplateFavorites) {

    state.threadTemplateFavorites = threadTemplateFavorites;
  },
  [Types.ADD_THREAD_TEMPLATE_FAVORITE](state, threadTemplateFavorites) {

    state.threadTemplateFavorites = threadTemplateFavorites;
  },
  [Types.REMOVE_THREAD_TEMPLATE_FAVORITE](state, threadTemplateId) {

    const index = state.threadTemplateFavorites.findIndex((threadTemplate) => threadTemplate.templateId === threadTemplateId);
    state.threadTemplateFavorites.splice(index, 1);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
