export const price = (row, column, cellValue, locale) => {

  return new Intl.NumberFormat(locale, {
    style : 'currency', currency : 'EUR', minimumFractionDigits : 0
  }).format(cellValue);
};

export default {
  price
};
