import * as Types from '../mutationTypes';

import HestiaApi from '@/api';

// initial state
const state = {
  accesses : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  }
};

// getters
const getters = {
  accesses : state => state.accesses
};

// actions
const actions = {
  async addAccess({ commit }, { tenantId, access }) {

    const response = await HestiaApi.Acl.access.create(tenantId, access).json();

    commit(Types.ADD_ACCESS, response);

    return response;
  },
  async getAccesses({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Acl.access.list(tenantId, { searchParams : query }).json();

    commit(Types.RECEIVE_ACCESSS, response);
  },
  async answerAccess({ commit }, { tenantId, accessId, action }) {

    let response;

    if (action === 'accept') {
      response = await HestiaApi.Acl.access.accept(tenantId, accessId, action).json();
    } else {
      response = await HestiaApi.Acl.access.refuse(tenantId, accessId, action).json();
    }

    commit(Types.ANSWER_ACCESS, response);

    return response.resource;
  }
};

// mutations
const mutations = {
  [Types.RECEIVE_ACCESSS](state, accesses) {

    state.accesses = accesses;
  },
  [Types.ADD_ACCESS](state, access) {

    state.accesses.results.push(access);
  },
  [Types.ANSWER_ACCESS](state, access) {

    state.accesses.results = state.accesses.results.map((a) => {

      if (a.id === access.id) {

        return access;
      }

      return a;
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
