import Cookies           from 'js-cookie';
import Vue               from 'vue';
import VueI18n           from 'vue-i18n';
import elementEnLocale   from '@pixul/element-ui/lib/locale/lang/en';
import elementFrLocale   from '@pixul/element-ui/lib/locale/lang/fr';
import enJson            from './en.json';
import frJson            from './fr.json';
import { i18n as AI18n } from '@pixul/aglaea';
import merge             from 'lodash.merge';
import moment            from 'moment';

const allMessages = { en : enJson, fr : frJson };
merge(allMessages, AI18n.messages);
Vue.use(VueI18n);

const messages = {
  en : {
    ...allMessages.en,
    ...elementEnLocale
  },
  fr : {
    ...allMessages.fr,
    ...elementFrLocale
  }
};

const i18n = new VueI18n({
  locale : Cookies.get('language') || 'en', // set locale
  messages // set locale messages
});

moment.locale(i18n.locale);

export default i18n;
